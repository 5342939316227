var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overflow-scroll"},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('div',{staticStyle:{"min-width":"1240px"}},[_c('a-card',{attrs:{"bordered":false,"tabList":_vm.tabListNoTitle,"activeTabKey":_vm.noTitleKey},on:{"tabChange":key => _vm.onTabChange(key, 'noTitleKey')}},[(_vm.noTitleKey === 'baseDetail' && !this.loading)?_c('a-card',{staticStyle:{"margin-left":"-32px"},attrs:{"bordered":false}},[_c('DescriptionList',{staticStyle:{"margin-top":"-24px","margin-bottom":"-48px"},attrs:{"title":"品牌信息","size":"large"}},[_c('a-card',{staticClass:"storeNewStoreLabelWidth6",staticStyle:{"margin-left":"-16px"},attrs:{"bordered":false}},[_c('div',{staticClass:"table-operator"},[_c('a-form',{attrs:{"layout":"inline","form":_vm.form}},[_c('a-row',{staticStyle:{"line-height":"4"},attrs:{"gutter":5}},[_c('a-col',{attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"店铺名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'storeName',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ],
                              initialValue: _vm.editDetail.storeName
                            }
                          ]),expression:"[\n                            'storeName',\n                            {\n                              rules: [\n                                {\n                                  required: true,\n                                  message: '请输入！'\n                                }\n                              ],\n                              initialValue: editDetail.storeName\n                            }\n                          ]"}],staticStyle:{"width":"240px"},attrs:{"placeholder":"请输入"}})],1)],1),_c('a-col',{attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"店铺状态"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'storeStatus',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请选择！'
                                }
                              ],
                              initialValue: _vm.editDetail.storeStatus
                            }
                          ]),expression:"[\n                            'storeStatus',\n                            {\n                              rules: [\n                                {\n                                  required: true,\n                                  message: '请选择！'\n                                }\n                              ],\n                              initialValue: editDetail.storeStatus\n                            }\n                          ]"}],staticStyle:{"width":"240px"},attrs:{"placeholder":"请选择"}},_vm._l((_vm.storeStatusList),function(item){return _c('a-select-option',{key:item.storeStatus},[_vm._v(_vm._s(item.storeStatusShow))])}),1)],1)],1)],1)],1)],1)])],1)],1):_vm._e()],1),_c('div',{staticStyle:{"margin-top":"8px"}},[_c('a-card',{attrs:{"bordered":false}},[_c('div',{staticClass:"steps-action"},[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('a-button',{staticStyle:{"margin-left":"8px"},attrs:{"type":"primary"},on:{"click":_vm.conserve}},[_vm._v("保存")])],1)])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }