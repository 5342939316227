<template>
  <div class="overflow-scroll">
    <a-spin :spinning="loading">
      <div style="min-width: 1240px">
        <a-card
          :bordered="false"
          :tabList="tabListNoTitle"
          :activeTabKey="noTitleKey"
          @tabChange="key => onTabChange(key, 'noTitleKey')"
        >
          <a-card
            v-if="noTitleKey === 'baseDetail' && !this.loading"
            :bordered="false"
            style="margin-left: -32px"
          >
            <DescriptionList
              title="品牌信息"
              size="large"
              style="margin-top: -24px;margin-bottom: -48px"
            >
              <a-card
                :bordered="false"
                class="storeNewStoreLabelWidth6"
                style="margin-left: -16px"
              >
                <div class="table-operator">
                  <a-form layout="inline" :form="form">
                    <a-row :gutter="5" style="line-height: 4">
                      <a-col :md="8" :sm="24">
                        <a-form-item label="店铺名称">
                          <a-input
                            style="width: 240px"
                            v-decorator="[
                              'storeName',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: '请输入！'
                                  }
                                ],
                                initialValue: editDetail.storeName
                              }
                            ]"
                            placeholder="请输入"
                          />
                        </a-form-item>
                      </a-col>
                      <a-col :md="8" :sm="24">
                        <a-form-item label="店铺状态">
                          <a-select
                            style="width: 240px"
                            v-decorator="[
                              'storeStatus',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: '请选择！'
                                  }
                                ],
                                initialValue: editDetail.storeStatus
                              }
                            ]"
                            placeholder="请选择"
                          >
                            <a-select-option
                              v-for="item in storeStatusList"
                              :key="item.storeStatus"
                              >{{ item.storeStatusShow }}</a-select-option
                            >
                          </a-select>
                        </a-form-item>
                      </a-col>
                    </a-row>
                  </a-form>
                </div>
              </a-card>
            </DescriptionList>
          </a-card>
        </a-card>
        <div style="margin-top: 8px">
          <a-card :bordered="false">
            <div class="steps-action">
              <a-button @click="cancel">取消</a-button>
              <a-button
                type="primary"
                style="margin-left: 8px"
                @click="conserve"
                >保存</a-button
              >
            </div>
          </a-card>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import DescriptionList from "@/components/DescriptionList";
  
import Bus from "@/bus";

import moment from "moment";
import axios from "axios";

export default {
  name: "shopEdit",
  components: {
    DescriptionList
  },
  data() {
    return {
      loading: false,
      editDetail: undefined,
      tabListNoTitle: [
        {
          key: "baseDetail",
          tab: "基本信息"
        }
      ],
      noTitleKey: "baseDetail",
      form: this.$form.createForm(this),
      defaultData: "",

      //店铺状态
      storeStatusList: []
    };
  },
  created: async function() {
    this.loading = true;
    await Promise.all([this.getStoreStatus(), this.getDetail()]);
    this.loading = false;
    Bus.$on("getTarget", target => {
      this.$router.push({
        path: "/role/shopList"
      });
    });
  },
  methods: {
    moment,
    // 店铺状态
    getStoreStatus() {
      return axios({
        url:   this.$baseUrl + "/tenantStore/allStoreStatus",
        method: "GET"
      }).then(res => {
        if (res.data.rows) {
          this.storeStatusList = res.data.rows;
        }
      });
    },
    onTabChange(key, type) {
      this[type] = key;
    },
    //修改店铺
    updateStore(val) {
      axios({
        method: "POST",
        url:   this.$baseUrl + "/tenantStore/update",
        headers: {
          Accept: "*/*"
        },
        data: {
          ...val,
          id: this.editDetail.storeId
        }
      })
        .then(res => {
          if (res.data.success) {
            this.$router.push({ path: "/role/shopList" });
            this.$message.success("修改店铺成功！");
          } else {
            this.$message.error(`${res.data.returnMsg}`);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    conserve() {
      //点击保存
      let that = this;
      const {
        form: { validateFields }
      } = this;
      this.confirmLoading = true;
      validateFields((errors, values) => {
        if (!errors) {
          that.updateStore(values);
        }
      });
    },
    getDetail() {
      return axios({
        url:   this.$baseUrl + "tenantStore/detail",
        method: "GET",
        params: {
          storeId: this.$route.query.id
        }
      }).then(res => {
        this.editDetail = res.data.obj;
      });
    },
    cancel() {
      // 点击取消
      this.$router.push({ path: "/role/shopList" });
    }
  }
};
</script>

<style scoped>
.steps-action {
  float: right;
}
</style>
